import React from "react"
import { graphql } from "gatsby"
import { getImage } from "gatsby-plugin-image"
import {
  Layout,
  Seo,
  SideBySide,
  SideImage,
  SideText,
  TextContent,
} from "../../components"
import * as textStyles from "../../components/text-content.module.scss"

export default function Hoortoestellen({ data }) {
  var sideImage = getImage(data.file)
  var title = "Hoortoestellen"

  return (
    <Layout site="audio" page={`/producten`}>
      <Seo title={title} shop="audio" />
      <SideBySide>
        <SideImage image={sideImage} />
        <SideText>
          <TextContent title={title} span="Een hooroplossing op maat.">
            <p className={textStyles.paragraph}>
              Wim biedt u een brede waaier aan hoortoestellen van topmerken aan,
              gaande van:
            </p>
            <div className={textStyles.paragraph}>
              <ul>
                <li>
                  Budget: Eenvoudige oplossing voor een beter spraakverstaan.
                </li>
                <li>
                  Basic: Voor een perfect geluid en spraakverstaan in alledaagse
                  en rustige situaties.
                </li>
                <li>
                  Plus: Voor personen met een sociaal actieve levensstijl en
                  wisselende luistersituaties.
                </li>
                <li>
                  Top: Voor de meest veeleisende gebruike en de meest complexe
                  luisteromgeving.
                </li>
              </ul>
            </div>
            <p className={textStyles.paragraph}>
              De modernste toestellen op de markt zijn nog nauwelijks zichtbaar,
              bieden vele mogelijkheden en een aangenaam draagcomfort!
              Afhankelijk van het gehoorverlies en de levensstijl kan gekozen
              worden voor:
            </p>
            <div className={textStyles.paragraph}>
              <ul>
                <li>AHO: Achter het oor</li>
                <li>IHO: Volledig in het oor</li>
                <li>RIE: Luidspreker in het oor</li>
              </ul>
            </div>
            <p className={textStyles.paragraph}>
              Ook voor wie zijn hoorapparaten wil bedienen met de smartphone en
              tablet of koppelen aan de audio-installatie of televisie, bieden
              we tal van mogelijkheden. Voorts kan je ook steeds bij ons terecht
              voor onderhoudsproducten en hoortoestelbatterijen.
            </p>
          </TextContent>
        </SideText>
      </SideBySide>
    </Layout>
  )
}

export const pageQuery = graphql`
  query {
    file(name: { eq: "hoortoestellen-menu" }) {
      childImageSharp {
        gatsbyImageData(quality: 95, width: 1920)
      }
    }
  }
`
